<template>
    <div v-cloak>
        <div class="report-container column-report">
            <div class="row mt-50 bottom-line">
                <div class="col-lg-8 report-title"><h1>SUMMARY INFORMATION</h1></div>
                <div class="col-lg-4 report-logo"><img width="200" src="/images/assets/logo/PRDB_logotype_multicolor.png"></div>
            </div>
            <div class="row mt-20">
                <div class="col-lg-2 col-xs-6">
                    <h3>PROJECT TYPE</h3>
                    <div v-for="(pType, key) in pTypeList" v-bind:key="key" class="status-btn">{{pType}}</div>
                </div>
                <div class="col-lg-2 col-xs-6">
                    <h3>CITY</h3>
                    <div v-for="(city, key) in citySelected" v-bind:key="key" class="status-btn">{{city}}</div>
                </div>
                <div class="col-lg-2 col-xs-6 type-col">
                    <h3>UNIT TYPE</h3>
                    <div v-for="(gut, key) in generalUnitType" v-bind:key="key" class="status-btn">{{gut}}</div>
                </div>
                <div class="col-lg-2 col-xs-6 type-col">
                    <h3>STATUS</h3>
                    <div v-for="(status, key) in statusList" v-bind:key="key" class="status-btn">{{status}}</div>
                </div>
                <div class="col-lg-4 type-col">
                    <div class="text-right project-status-btn" :class="statusOriginal">{{statusList[0]}}</div>
                </div>
            </div>
            <img class="spinner" v-if="is_loading" src="/images/spinner.gif">
            <div id="map-main" class="mt-20" v-show="mapToggle"></div>
            <div>
                <h2 style="float: left;">VIEW RESULTS</h2>
                <img style="float: right; cursor: pointer;" width="50" height="50" src="/images/assets/googlemap_icon.png" @click="mapToggle = !mapToggle"><img style="float: right; cursor: pointer; margin-right: 20px;" width="50" height="50" src="/images/assets/export_icon.png">
            </div>
            <div class="table-wrap">
                <table v-if="!is_loading" class="table list-table">
                    <thead class="summary-table-header">
                        <th scope="col" class="summary-sort" @click="sort('name')">Project Name</th>

                        <th v-for="(row, key) in reportFields" v-bind:key="key" scope="col" class="summary-sort" :class="{'hidden-xs' : !['project_type', 'general_unit_type', 'developer', 'dp_status', 'total_avg_size'].includes(row)}">
                            <div v-if="filterOptions[row] && filterOptions[row].length">
                                <span @click="displayOptions(row)" class="m-1">
                                    {{fieldSwitch(row)}} <i class="fa fa-angle-down" :class="optionsIsVisible[row] ? 'rotate-180' : 'rotate-360'"></i>
                                </span>
                                <ul :class="optionsIsVisible[row] ? 'visible' : 'invisible'">
                                    <template v-if="row == 'general_unit_type'">
                                        <li v-for="item in filterOptions[row]" 
                                            :key="item" 
                                            :class="filter[row].includes(item) ? 'selected' : ''"
                                            @click="containOption(row, item)"
                                        >
                                            <span v-if="row.includes('status')" :class="classStatus(item)"></span>{{item}}
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li v-for="item in filterOptions[row]" 
                                            :key="item" 
                                            :class="filter[row].includes(item) ? 'selected' : ''"
                                            @click="selectOption(row, item)"
                                        >
                                            <span v-if="row.includes('status')" :class="classStatus(item)"></span>{{item}}
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <span v-else @click="sort(row)">{{fieldSwitch(row)}}</span>
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(row, key) in filteredReportDetail" v-bind:key="key">
                            <td>
                                <div><a :href="row.ref_link">{{row.name}}</a></div>
                                <div>{{row.address}}</div>
                            </td>
                            <td v-for="(field, index) in reportFields" v-bind:key="index" :class="{'hidden-xs' : !['project_type', 'general_unit_type', 'developer', 'dp_status', 'total_avg_size'].includes(field)}">
                                <span v-if="field.includes('status')" :class="classStatus(dataSwitch(field, row))"></span>
                                <span v-if="field=='project_type'">{{dataSwitch(field, row)}}<br>{{row.storey}}</span>
                                <span v-else v-html='dataSwitch(field, row)'></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-xs-12 status-wrapper">
                        <div style="float: left; margin-left: 20px;" class="project-status-btn" :class="statusOriginal">{{statusList[0]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { BACKEND_URL } from '../variables.js';
    import { cloneDeep } from 'lodash';
    import { classStatus } from '../shared/services'
    
    export default {
        components: { 
        },
        data() {
            return {
                query: this.$route.query,
                is_loading: true,
                reportDetail: [],
                filteredReportDetail: [],
                reportFields: ['project_type', 'developer'],
                filter: {},
                optionsIsVisible: {},
                filterOptions: {},
                statusList: [],
                pTypeList: [],
                cityList: [],
                generalUnitType: [],
                gutoptions: [],
                asc: true,
                locations: [],
                mapToggle: false,
                cityoptions: [],
                statusOriginal: null,
                reportType: 'Project Report',
                citySelected: []
            }
        },
        methods: {
            numberWithCommas: function(x) {
                if (x) {
                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                else {
                    return x;
                }
            },
            mainMap: function() {
                const vm = this;
                var locations = this.locations;
                var map = new google.maps.Map(document.getElementById('map-main'), {
                    scrollwheel: false,
                    center: new google.maps.LatLng(49.10833262464712, -122.73111070997949),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    panControl: false,
                    fullscreenControl: true,
                    navigationControl: false,
                    streetViewControl: false,
                    animation: google.maps.Animation.BOUNCE,
                    gestureHandling: 'greedy',
                    zoom: 11,
                    styles: [{
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#444444"
                        }]
                    }]
                });

                var oms = new OverlappingMarkerSpiderfier(map,{markersWontMove: true, markersWontHide: true, keepSpiderfied: true});
                var blueMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#6EBBF8" stroke="white" stroke-width="3"/></svg>';
                var greenMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#60B85F" stroke="white" stroke-width="3"/></svg>';
                var orangeMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#FF9357" stroke="white" stroke-width="3"/></svg>';
                var yellowMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#f5f242" stroke="white" stroke-width="3"/></svg>';
                var navyMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#0b4add" stroke="white" stroke-width="3"/></svg>';
                var redMarker = '<svg width="35" height="51" viewBox="0 0 35 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0C7.8505 0 0 8.04326 0 17.9297C0 21.27 0.903098 24.5298 2.61226 27.3579L16.5039 50.2937C16.77 50.733 17.2388 51 17.743 51C17.7468 51 17.7506 51 17.7545 51C18.2631 50.9959 18.7329 50.7207 18.9941 50.2737L32.5316 27.1157C34.1465 24.3473 35 21.1709 35 17.9297C35 8.04326 27.1495 0 17.5 0Z" fill="#5E6274"/><path d="M26.2243 19.3671C26.2243 24.2778 22.3481 28.1962 17.6427 28.1962C12.9374 28.1962 9.06116 24.2778 9.06116 19.3671C9.06116 14.4564 12.9374 10.538 17.6427 10.538C22.3481 10.538 26.2243 14.4564 26.2243 19.3671Z" fill="#ff1953" stroke="white" stroke-width="3"/></svg>';
                oms.addListener('format', function(marker, status) {
                    marker.set('noHover', false);
                    if (status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED) {
                        var iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(blueMarker);
                        if (marker.icon_id == 2) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(yellowMarker);
                        }
                        if (marker.icon_id == 3) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(redMarker);
                        }
                        marker.setIcon({
                            anchor: new google.maps.Point(22, 16),
                            url: iconSvg,
                        });
                    }
                    // else if (status == OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE) {
                        
                    // }
                    else if (status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) {
                        var iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(navyMarker);
                        if (marker.icon_id == 2) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(yellowMarker);
                        }
                        if (marker.icon_id == 3) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(redMarker);
                        }
                        marker.setIcon({
                            anchor: new google.maps.Point(22, 16),
                            url: iconSvg,
                        });
                        marker.set('noHover', true);
                    }
                    else {
                        var iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(blueMarker);
                        if (marker.icon_id == 2) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(yellowMarker);
                        }
                        if (marker.icon_id == 3) {
                            iconSvg = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(redMarker);
                        }
                        marker.setIcon({
                            anchor: new google.maps.Point(22, 16),
                            url: iconSvg,
                        });
                    }
                });
                // $(document).on('change', '.rzCheck, .dpCheck, .bpCheck', function() {
                //     statusFilter();
                // });

                var boxText = document.createElement("div");
                boxText.className = 'map-box'
                var currentInfobox;
                var boxOptions = {
                    content: boxText,
                    disableAutoPan: true,
                    alignBottom: true,
                    maxWidth: 0,
                    pixelOffset: new google.maps.Size(-145, -45),
                    zIndex: null,
                    boxStyle: {
                        width: "260px"
                    },
                    closeBoxMargin: "0",
                    closeBoxURL: "",
                    infoBoxClearance: new google.maps.Size(1, 1),
                    isHidden: false,
                    pane: "floatPane",
                    enableEventPropagation: false,
                    contextmenu:true,
                };
                var markerCluster, marker, i;
                var allMarkers = [];
                var clusterStyles = [{
                    textColor: 'white',
                    url: '',
                    height: 50,
                    width: 50
                }];
        
                for (i = 0; i < locations.length; i++) {
                    var icon = navyMarker;
                    if (locations[i]['icon'] == 2) {
                        icon = orangeMarker;
                    }
                    if (locations[i]['icon'] == 3) {
                        icon = redMarker;
                    }
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(locations[i]['geo']['lat'], locations[i]['geo']['lng']),
                        icon: icon,
                        icon_id: locations[i]['icon'],
                        id: i,
                        boxContent: locations[i]['content'],
                        noHover: false
                    });
                    allMarkers.push(marker);
                    oms.addMarker(marker);
                    var ib = new InfoBox();
                    google.maps.event.addListener(ib, "domready", function () {
                        cardRaining()
                    });
                    // if (this.initBeforeSearch) {
                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            if (!marker.noHover) {
                                ib.setOptions(boxOptions);
                                boxText.innerHTML = marker.boxContent;
                                currentInfobox = marker.id;
                                google.maps.event.addListener(ib, 'domready', function () {
                                    $('.infoBox-close').click(function (e) {
                                        e.preventDefault();
                                        ib.close();
                                    });
                                });
                                ib.close();
                                ib.open(map, marker);
                                google.maps.event.addListener(map, 'click', function () {
                                    ib.close();
                                    vm.closeMapBox();
                                });
                            }
                        }
                    })(marker, i));
                }
                var options = {
                    imagePath: '/images/',
                    styles: clusterStyles,
                    minimumClusterSize: 2
                };
                markerCluster = new MarkerClusterer(map, allMarkers, options);
                markerCluster.setMaxZoom(15);

                google.maps.event.addDomListener(window, "resize", function () {
                    var center = map.getCenter();
                    google.maps.event.trigger(map, "resize");
                    map.setCenter(center);
                });

                // Scroll enabling button
                var scrollEnabling = $('.scrollContorl');

                $(scrollEnabling).click(function(e){
                    e.preventDefault();
                    $(this).toggleClass("enabledsroll");

                    if ( $(this).is(".enabledsroll") ) {
                        map.setOptions({'scrollwheel': true});
                    } else {
                        map.setOptions({'scrollwheel': false});
                    }
                });       
                var zoomControlDiv = document.createElement('div');
                var zoomControl = new ZoomControl(zoomControlDiv, map);

                function ZoomControl(controlDiv, map) {
                    zoomControlDiv.index = 1;
                    map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(zoomControlDiv);
                    controlDiv.style.padding = '5px';
                    var controlWrapper = document.createElement('div');
                    controlDiv.appendChild(controlWrapper);
                    var zoomInButton = document.createElement('div');
                    zoomInButton.className = "mapzoom-in";
                    controlWrapper.appendChild(zoomInButton);
                    var zoomOutButton = document.createElement('div');
                    zoomOutButton.className = "mapzoom-out";
                    controlWrapper.appendChild(zoomOutButton);
                    google.maps.event.addDomListener(zoomInButton, 'click', function () {
                        map.setZoom(map.getZoom() + 1);
                    });
                    google.maps.event.addDomListener(zoomOutButton, 'click', function () {
                        map.setZoom(map.getZoom() - 1);
                    });
                }

            },
            sort(name) {
                this.asc = !this.asc
                let alphSorting = {
                    'application_stage' : 'application_stage',
                    'developer' : 'developer',
                    'name' : 'name',
                }
                var vm = this;
                var ob = this.filteredReportDetail;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == name) {
                        if (vm.asc) {
                            ob = vm.filteredReportDetail.sort((a,b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.filteredReportDetail.sort((a,b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });

                let dateSorting = {
                    'sales_start_date' : 'sales_start_date',
                    'application_date' : 'application_date',
                    'sold_out_date' : 'sold_out_date',
                    'updated' : 'updated',
                }
                Object.keys(dateSorting).forEach(function (k) {
                    if (k == name) {
                        if (vm.asc) {
                            ob = vm.filteredReportDetail.sort((a,b) => 
                                {
                                    if (a[dateSorting[k]] == '') {
                                        return 1;
                                    }
                                    if (b[dateSorting[k]] == '') {
                                        return -1;
                                    }
                                    if (Date.parse(a[dateSorting[k]]) > Date.parse(b[dateSorting[k]])) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                            );
                        }
                        else {
                            ob = vm.filteredReportDetail.sort((a,b) => 
                                {
                                    if (a[dateSorting[k]] == '') {
                                        return -1;
                                    }
                                    if (b[dateSorting[k]] == '') {
                                        return 1;
                                    }
                                    if (Date.parse(a[dateSorting[k]]) > Date.parse(b[dateSorting[k]])) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                        }
                    }
                });

                let digitSorting = {
                    'total_units' : 'total_units',
                    'rlsd_units' : 'rlsd_units',
                    'sold_units' : 'sold_units',
                    'available' : 'available',
                    'total_avg_size' : 'total_avg_size',
                    'total_avg_psf' : 'total_avg_psf',
                    'total_avg_price' : 'total_avg_price',
                    'storey' : 'storey',
                    'current_avg_size' : 'current_avg_size',
                    'current_avg_price' : 'current_avg_price',
                    'current_avg_psf' : 'current_avg_psf',
                }
                Object.keys(digitSorting).forEach(function (k) {
                    if (k == name) {
                        if (vm.asc) {
                            ob = vm.filteredReportDetail.sort((a,b) => 
                                {
                                    if (a[digitSorting[k]] == '' || a[digitSorting[k]] == null) {
                                        if (b[digitSorting[k]] > 0) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (b[digitSorting[k]] == '' || b[digitSorting[k]] == null) {
                                        if (a[digitSorting[k]] > 0) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (parseInt(a[digitSorting[k]]) > parseInt(b[digitSorting[k]])) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                            );
                        }
                        else {
                            ob = vm.filteredReportDetail.sort((a,b) => 
                                {
                                    if (a[digitSorting[k]] == '' || a[digitSorting[k]] == null) {
                                        if (b[digitSorting[k]] > 0) {
                                            return 1;
                                        }
                                        else {
                                            return -1;
                                        }
                                    }
                                    if (b[digitSorting[k]] == '' || b[digitSorting[k]] == null) {
                                        if (a[digitSorting[k]] > 0) {
                                            return -1;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }
                                    if (parseInt(a[digitSorting[k]]) > parseInt(b[digitSorting[k]])) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                            );
                        }
                    }
                });
                this.filteredReportDetail = ob
            },
            statusName (name) {
                switch (name) {
                    case 'now_selling':
                        return 'Now Selling';
                    case 'selling_soon':
                        return 'Selling Soon';
                    case 'in_planning':
                        return 'In Planning';
                    case 'sold_out':
                    case 'sold_out_2':
                        return 'Sold Out';
                    case 'new_leasing':
                        return 'Now Leasing';
                    case 'leased_out':
                        return 'Leased Out';
                    case 'leasing_soon':
                        return 'Leasing Soon';
                }
            },
            typeName (name) {
                switch (name) {
                    case '2886':
                        return 'Apartment';
                    case '2870':
                        return 'Townhome';
                    case '2884':
                        return 'Mixed-Use Apartment';
                    case '3479':
                        return 'Stacked Townhome';
                    case '2962':
                        return 'Commercial';
                    case '3047':
                        return 'Industrial';
                    case '6240':
                        return 'Row Home';
                    case '5910':
                        return 'Podium';
                }
            },
            classStatus: classStatus,
            displayOptions (field, skipCurrentField) {
                if (!skipCurrentField) this.optionsIsVisible[field] = !this.optionsIsVisible[field];
                Object.keys(this.optionsIsVisible).forEach((key) => {
                    if (key !== field) this.optionsIsVisible[key] = false;
                })
                this.optionsIsVisible = Object.assign({}, this.optionsIsVisible);
            },
            containOption (field, selectedItem) {
                if (this.filter[field].includes(selectedItem)) {
                    this.filter[field].splice(this.filter[field].indexOf(selectedItem), 1)
                } else {
                    this.filter[field].push(selectedItem);
                }
                this.filter = Object.assign({}, this.filter);
                this.filterReportDetailContains(field);
            },
            selectOption (field, selectedItem) {
                if (this.filter[field].includes(selectedItem)) {
                    this.filter[field].splice(this.filter[field].indexOf(selectedItem), 1)
                } else {
                    this.filter[field].push(selectedItem);
                }
                this.filter = Object.assign({}, this.filter);
                this.filterReportDetail(field);
            },
            fieldSwitch(field) {
                switch (field) {
                    case 'project_type':
                        return "Type & Storey";
                    case 'general_unit_type':
                        return 'Unit Type';
                    case 'rezoning_status':
                        return 'Rezoning Status';
                    case 'dp_status':
                        return 'DP Status';
                    case 'bp_status':
                        return 'BP Status';
                    case 'floor_area':
                        return 'Floor Area';
                    case 'units_utc_da':
                        return 'Units DA';
                    case 'units_utc':
                        return 'Units';
                    case 'construction_status':
                        return 'Construction Status';
                    case 'presale_name':
                        return 'Presale Name';
                    case 'presale_lot_area':
                        return 'Total Lot Area (Sqft)';
                    case 'nid':
                        return 'Page ID';
                    case 'status':
                        return 'Project Status';
                    case 'developer':
                        return 'Developer';
                    case 'current_avg_psf':
                        return 'Current Average $PSF';
                    case 'sold_units':
                        return 'Sold Units';
                    case 'sales_start_date':
                        return 'Sales Start';
                    case 'project_market':
                        return 'Project Marketing';
                    case 'general_contractor':
                        return 'General Contractor';
                    case 'construction_start_date':
                        return 'Construction Start Date';
                    case 'construction_complete_date':
                        return 'Construction Completion Date';
                    case 'total_units':
                        return 'Total Units';
                    case 'rlsd_units':
                        return 'Rlsds Units';
                    case 'available':
                        return 'Avail Units';
                    case 'total_psf':
                        return 'Total PSF';
                    case 'total_sold_units':
                        return 'Sold Units';
                    case 'total_avg_size':
                        return 'Avg Size';
                    case 'sold_out_date':
                        return 'Sales Finish';
                    case 'total_avg_psf':
                        return 'Avg $PSF';
                    case 'total_avg_price':
                        return 'Avg Price';
                    case 'updated':
                        return 'Updated';
                    case 'current_avg_size':
                        return 'Current Avg Size';
                    case 'current_avg_price':
                        return 'Current Avg Price';
                    case 'storey':
                        return 'Storey';
                    case 'application_stage':
                        return 'Application Stage';
                    case 'application_date':
                        return 'Application Date';
                }
            },
            dataSwitch(field, data) {
                switch (field) {
                    case 'project_type':
                        return data.project_type;
                    case 'general_unit_type':
                        return data.type;
                    case 'rezoning_status':
                        return data.rz_status ? data.rz_status : '-';
                    case 'dp_status':
                        return data.dp_status ? data.dp_status : '-';
                    case 'bp_status':
                        return data.bp_status ? data.bp_status : '-';
                    case 'units_utc_da':
                        return data.units_utc_da ? data.units_utc_da : '-';
                    case 'floor_area':
                        return data.floor_area ? data.floor_area : '-';
                    case 'units_utc':
                        return data.units_utc ? data.units_utc : '-';
                    case 'construction_status':
                        return data.construction_status ? data.construction_status : '-';
                    case 'presale_name':
                        return data.presale_name ? data.presale_name : '-';
                    case 'presale_lot_area':
                        return data.presale_lot_area ? data.presale_lot_area : '-';
                    case 'nid':
                        return data.nid ? data.nid : '-';
                    case 'status':
                        return data.status ? data.status : '-';
                    case 'developer':
                        return data.developer ? data.developer : '-';
                    case 'total_avg_psf':
                        return data.total_avg_psf ? '$' + this.numberWithCommas(data.total_avg_psf) : '-';
                    case 'current_avg_psf':
                        return data.current_avg_psf ? '$' + this.numberWithCommas(data.current_avg_psf) : '-';
                    case 'sales_start_date':
                        return data.sales_start_date ? data.sales_start_date : '-';
                    case 'sold_out_date':
                        return data.sold_out_date ? data.sold_out_date : '-';
                    case 'project_market':
                        return data.project_marketing ? data.project_marketing : '-';
                    case 'general_contractor':
                        return data.general_contractor ? data.general_contractor : '-';
                    case 'construction_start_date':
                        return data.construction_start_date ? data.construction_start_date : '-';
                    case 'construction_complete_date':
                        return data.construction_complete_date ? data.construction_complete_date : '-';
                    case 'original_type':
                        return data.original_type ? data.original_type : '-';
                    case 'total_units':
                        return data.total_units ? data.total_units : '-';
                    case 'total_psf':
                        return data.total_psf ? data.total_psf : '-';
                    case 'total_sold_units':
                        return (data.sold_units === '0' || data.sold_units > 0) ? data.sold_units : '-';
                    case 'sold_units':
                        return (data.sold_units === '0' || data.sold_units > 0) ? data.sold_units : '-';
                    case 'rlsd_units':
                        return (data.rlsd_units === '0' || data.rlsd_units > 0) ? data.rlsd_units : '-';
                    case 'available':
                        return data.available ? data.available : '0';
                    case 'updated':
                        return data.updated ? data.updated : '-';
                    case 'total_avg_size':
                        return data.total_avg_size ? this.numberWithCommas(data.total_avg_size) : '-';
                    case 'total_avg_price':
                        return data.total_avg_price ? '$' + this.numberWithCommas(data.total_avg_price) : '-';
                    case 'current_avg_size':
                        return data.current_avg_size ? this.numberWithCommas(data.current_avg_size) : '-';
                    case 'current_avg_price':
                        return data.current_avg_price ? '$' + this.numberWithCommas(data.current_avg_price) : '-';
                    case 'storey':
                        return (data.storey === '0' || data.storey > 0) ? data.storey : '-';
                    case 'application_stage':
                        return data.application_stage ? data.application_stage : '-';
                    case 'application_date':
                        return data.application_date ? data.application_date : '-';
                }
            },
            filterReportDetailContains (field) {
                this.displayOptions(field, true);
                this.filteredReportDetail = this.reportDetail
                    .filter((item) => (!this.filter[field].length || this.filter[field].some(el => this.dataSwitch(field, item).includes(el))))
                Object.keys(this.filter).forEach((key) => {
                    if (key !== field) this.filter[key] = []
                })
            },
            filterReportDetail (field) {
                this.displayOptions(field, true);
                this.filteredReportDetail = this.reportDetail
                    .filter((item) => (!this.filter[field].length || this.filter[field].includes(this.dataSwitch(field, item))))
                Object.keys(this.filter).forEach((key) => {
                    if (key !== field) this.filter[key] = []
                })
            },
            unique(arr){
                var newArr = [];
                for(var i = 0; i < arr.length; i++){
                    if(arr[i] && newArr.indexOf(arr[i]) === -1){
                        newArr.push(arr[i])
                    }
                }
                return newArr;
            },      
            getReportDetails (params) {
                let uids = JSON.parse(localStorage.getItem('uids'));
                if (!uids) {
                    const endPoint = `api/get_projects_report_utc?${params}`;
                    this.is_loading = true;
                    $.get({
                        url: BACKEND_URL + endPoint,
                    }, (data, status) => {
                        this.is_loading = false;
                        if (status !== 'success') return;
                        const keys = ['project_type', 'rezoning_status', 'dp_status', 'bp_status', 'construction_status', 'status']
                        keys.forEach((key) => {
                            this.filter[key] = [];
                            this.optionsIsVisible[key] = false;
                            this.filterOptions[key] = this.unique(data.map((item) => this.dataSwitch(key, item)));
                        })
    
                        let gut_all = [];
                        for (let val of data) {
                            for (let obj of val.original_type) {
                                gut_all.push(obj)
                            }
                        }
                        this.filter['general_unit_type'] = [];
                        this.optionsIsVisible['general_unit_type'] = false;
                        this.filterOptions['general_unit_type'] = this.unique(gut_all);
    
                        this.reportDetail = cloneDeep(data);
                        this.filteredReportDetail = cloneDeep(data);
                        const vm = this
                        _.each(data, (project) => {
                            vm.locations.push({
                                'icon': project.icons,
                                'geo' : project.geo,
                                'content': vm.locationData(project),
                            });
                        });
                        this.mainMap();
                    });
                }
                else {
                    const endPoint = `api/get_projects_report_utc?${params}`;
                    this.is_loading = true;
                    $.get(BACKEND_URL + endPoint, {
                        uids: uids
                    }, (data, status) => {
                        this.is_loading = false;
                        if (status !== 'success') return;
                        const keys = ['project_type', 'rezoning_status', 'dp_status', 'bp_status', 'construction_status', 'status']
                        keys.forEach((key) => {
                            this.filter[key] = [];
                            this.optionsIsVisible[key] = false;
                            this.filterOptions[key] = this.unique(data.map((item) => this.dataSwitch(key, item)));
                        })
    
                        let gut_all = [];
                        for (let val of data) {
                            for (let obj of val.original_type) {
                                gut_all.push(obj)
                            }
                        }
                        this.filter['general_unit_type'] = [];
                        this.optionsIsVisible['general_unit_type'] = false;
                        this.filterOptions['general_unit_type'] = this.unique(gut_all);
    
                        this.reportDetail = cloneDeep(data);
                        this.filteredReportDetail = cloneDeep(data);
                    });
                }
            },
            clickFunc (event) {
                if (!['LI', 'UL', 'SPAN'].includes(event.target.nodeName)) {
                    Object.keys(this.optionsIsVisible).forEach((key) => {
                        this.optionsIsVisible[key] = false
                    })
                }
            },
            locationData: function(project) {
                var vm = this;
                let popuphtml = `
                <div class="map-popup-wrap">
                    <div class="map-popup">
                        <div class="infoBox-close"><i class="fa fa-times"></i></div>
                        <div class="listing-title fl-wrap">
                            <div style="padding: 0 5px; display: inline-block;">
                            <h4>`+project.status+`</h4>
                                <h4>`+project.name+`</h4>
                                <h4>`+project.type+`</h4>
                                <h4>`+project.total_units+` Units</h4>
                            </div>`;
                        popuphtml += `</div>
                    </div>
                </div>`;
                return popuphtml;
            }
        },
        mounted: function() {
            $.get({
                url: BACKEND_URL + 'api/get_region',
            }, (data, status) => {
                if (status === 'success') {
                    this.cityoptions = data;
                    this.cityoptions.forEach((key) => {
                        this.cityList.forEach((val) => {
                            if (key.id == val) {
                                this.citySelected.push(key.label);
                            }
                        });
                    });
                }
            });
            $.get({
                url: BACKEND_URL + 'api/get_gut_children',
            }, (data, status) => {
                if (status === 'success') {
                    this.gutoptions = data;
                    Object.keys(this.gutoptions).forEach((key) => {
                        if (vm.query['unittype[]'].includes(this.gutoptions[key].id)) {
                            this.generalUnitType.push(this.gutoptions[key].label)
                        }
                    })
                }
            });
            const vm = this;
            window.addEventListener('click', this.clickFunc, false);
            let param = '';
            if ($.isArray(vm.query['type[]'])) {
                vm.query['type[]'].forEach((a) => {
                    param += 'type[]=' + a + '&';
                    this.pTypeList.push(this.typeName(a));
                });
            }
            else if (typeof vm.query['type[]'] == 'undefined') {

            }
            else {
                param += 'type[]=' + vm.query['type[]'] + '&';
                this.pTypeList.push(this.typeName(vm.query['type[]']));
            }
            if ($.isArray(vm.query['city[]'])) {
                vm.query['city[]'].forEach((a) => {
                    param += 'city[]=' + a + '&';
                    this.cityList.push(a);
                });
            }
            else if (typeof vm.query['city[]'] == 'undefined') {
            }
            else {
                param += 'city[]=' + vm.query['city[]'] + '&';
                this.cityList.push(vm.query['city[]']);
            }
            if ($.isArray(vm.query['constructionstatus[]'])) {
                vm.query['constructionstatus[]'].forEach((a) => {
                    param += 'constructionstatus[]=' + a + '&';
                });
            }
            else if (typeof vm.query['constructionstatus[]'] == 'undefined') {

            }
            else {
                param += 'constructionstatus[]=' + vm.query['constructionstatus[]'] + '&';
            }
            if ($.isArray(vm.query['unittype[]'])) {
                vm.query['unittype[]'].forEach((a) => {
                    param += 'unittype[]=' + a + '&';
                });
            }
            else if (typeof vm.query['unittype[]'] == 'undefined') {

            }
            else {
                param += 'unittype[]=' + vm.query['unittype[]'] + '&';
            }
            if ($.isArray(vm.query['report[]'])) {
                vm.query['report[]'].forEach((a) => {
                    if (a == 'project_report') {
                        this.reportType = "Project Report";
                    }
                    else if (a == 'current_availability') {
                        this.reportType = "Current Availability";
                    }
                    else if (a == 'planning_report') {
                        this.reportType = "Planning Report";
                    }
                    else {
                        vm.reportFields.push(a);
                    }
                });
            }
            else if (typeof vm.query['report[]'] == 'undefined') {

            }
            else {
                vm.reportFields = [vm.query['report[]']];
            }
            let dateList = [
                'rzSubDate',
                'rzAppDate',
                'dpSubDate',
                'dpIssueDate',
                'bpIssueDate',
                'bpIssueDate',
                'rzStatus',
                'bpStatus',
                'dpStatus'
            ];
            dateList.forEach((d) => {
                if ($.isArray(vm.query[d+'[]'])) {
                    vm.query[d+'[]'].forEach((a) => {
                        param += d+'[]=' + a + '&';
                    });
                }
                else if (typeof vm.query[d+'[]'] == 'undefined') {

                }
                else {
                    param += d+'[]=' + vm.query[d+'[]'] + '&';
                }
            });
            if (vm.query['search']) {
                param += 'search='+vm.query['search'] + '&';;
            }
            if (vm.query['developer']) {
                param += 'developer='+vm.query['developer']+  '&';;
            }
            if ($.isArray(vm.query['status[]'])) {
                vm.query['status[]'].forEach((a) => {
                    param += 'status[]=' + a + '&';
                    this.statusList.push(this.statusName(a));
                    if (!this.statusOriginal) {
                        this.statusOriginal = a
                    }
                });
            }
            else if (typeof vm.query['status[]'] == 'undefined') {

            }
            else {
                param += 'status[]=' + vm.query['status[]'];
                this.statusList.push(this.statusName(vm.query['status[]']));
                this.statusOriginal = vm.query['status[]'];
            }
            this.getReportDetails(param)
        },
        destroyed() {
            window.removeEventListener('click', this.clickFunc)
        }
    }

</script>
<style scoped>
.table {
    font-family: Poppins;
    font-weight: 300;
}
th>div {
    position: relative;
}
th>div>span {
    white-space: nowrap;
}
th>div>div {
    position: absolute;
}
fa {
    font-size: 18px;
}
th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}
.visible {
    /* max-height: 100px; */
    transition: max-height .3s;
}
.invisible {
    max-height: 0px;
    transition: max-height .3s;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}
.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}
th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}
th ul li:hover {
    background: #4db7fe;
    color: #fff;
}
th ul li:last-child {
    border-bottom: none;
}
.selected {
    background: #4db7fe;
    color: #fff;
}
a {
    font-weight: 500;
}

.map-btn {
    background: #0B4ADD;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #fff;
    font-weight: bold;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
    cursor: pointer;
}
.status-row {
    margin-top: 20px; 
    margin-bottom: 0px;
    text-align: left;
}
.status-line {
    line-height: 5;
}
.list-table thead.summary-table-header th {
    top: 0;
}
#map-main {
    height: 800px;
}
.summary-sort {
    position: sticky;
    background: #12283FE5;
    color: #fff;
    font-family: Poppins;
    font-weight: 500;
}
h1 {
    font-size: 3rem;
    font-family: Poppins;
    font-weight: 600;
}
h3 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: left;
}
h2 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 2rem;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}
.status-btn {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.3rem;
    text-align: left;
    margin-top: 8px;
}
.bottom-line {
    border-bottom: 2px solid #545454;
}
.list-table.table th {
    padding: 2.5rem 1.5rem;
}
.list-table.table th {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2rem;
}
.list-table.table td {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 30px;
}
th {
    line-height: 24px;
}
.project-status-btn {
    float: right;
    margin-right: 30px;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 15px 50px;
    border-radius: 42px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}
.project-status-btn.now_selling,
.project-status-btn.new_leasing {
    background: #18A01B;
}
.project-status-btn.selling_soon,
.project-status-btn.leasing_soon {
    background: #E97E21;
}
.project-status-btn.in_planning {
    background: #E7CE1C;
}
.project-status-btn.sold_out,
.project-status-btn.leased_out {
    background: #C2131E;
}
.project-status-btn.leasing_soon {
    background: #1227FA;
}
.report-title {
    text-align: left;
}
.report-logo {
    text-align: right;
}
.spinner {
    width: 300px;
}
@media screen and (max-width: 1100px) {
    .type-col {
        margin-top: 20px;
    }
    .table-wrap {
        width: 100%;
        overflow: scroll;
    }
    .hidden-xs {
        display:none !important
    }
    h3 {
        font-size: 1.1rem;
    }
    .report-title {
        text-align: center;
        order: 2;
        margin-bottom: 20px;
    }
    .report-logo {
        text-align: center;
        order: 1;
    }
    .status-wrapper {
        text-align: center;
    }
    .project-status-btn {
        float: none;
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

}
</style>
<style>
.listing-title {
    background: #fff;
    padding: 25px 20px;
    z-index: 20;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.table th, .table td {
    border-top: none;
}
</style>