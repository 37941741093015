import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '2f52e0c7-bf33-498b-b9e1-7410a6e15c69',
    clientToken: 'pubb917244263b18960556578f4cf6512c1',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'real-property-data',
    env: '<ENV_NAME>',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});