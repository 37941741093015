<template>
  <div>
    <template v-if="['planning'].includes(currentTab)">
      <b-table
        :sort-by="
          $route.path == '/type'
            ? 'mls_price_date'
            : 'field_mls_unit_size_value'
        "
        :sort-compare="sortDate"
        :ref="table_reference"
        :filter-function="filterTable"
        @filtered="onFiltered"
        responsive="sm"
        hover
        small
        :filter-included-fields="filterOn"
        :sort-desc="true"
        striped
        :filter="filter_rows"
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="bg-vb-secondary text-light position-sticky"
        :items="row_data"
        :fields="header_fields"
      >
        <template #head(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              dis
              size="lg"
              @change="onSelectAllRows"
              v-model="all_rows_selected"
              variant="danger"
              class="mr-1 ml-2 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        
        <template #head(customProjectName)="data">
          {{ data.label }}
          <button @click="$emit('refreshResults')" class="btn btn-light ml-3">UPDATE TABLE</button>
        </template>
        <template #cell(presale_id)="data">
          <h5 class="text-left pl-3">
            <a
              class="text-vb-primary"
              style="font-weight: 500; font-size: 20px"
              :href="ROOT_URL + '/project?project=' +data.value"
              >{{ data.value }}
            </a>
          </h5>
        </template>
        <template #cell(customPlanningData)="data">
          <template
            v-if="
              ['developer', 'developerbc', 'realtor'].includes(
                current_user.role
              )
            "
          >
            <template
              v-for="(value, key) in Object.fromEntries(
                Object.entries(data.value).filter(([k]) =>
                  ['REZ', 'DP'].includes(k)
                )
              )"
            >
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
          <template v-else>
            <template v-for="(value, key) in data.value">
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
        </template>
        <template #cell(project_name)="data">
          <h5 class="text-left pl-3">
            <a
              class="text-vb-primary"
              style="font-weight: 500; font-size: 20px"
              :href="data.item.link"
              >{{ data.value }}
            </a>
          </h5>
        </template>
        <template #cell(customData)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                  <font-awesome-icon
                    :icon="['fas', 'medal']"
                    v-if="isInCurrentQuarter(data.item.sales_start_date)"
                /></a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
            <!-- <div>
                            <font-awesome-icon class="_cursor-pointer" :icon="['far', 'heart']" />
                        </div> -->
          </div>
        </template>
        <template #cell(customProjectName)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                </a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
            <!-- <div>
                            <font-awesome-icon class="_cursor-pointer" :icon="['far', 'heart']" />
                        </div> -->
          </div>
        </template>
        <template #cell(customTypeStoreyData)="data">
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.projectType }}
          </p>
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            ({{ data.value.storey ? data.value.storey : "-" }})
          </p>
        </template>
        <template #cell(customLocationData)="data">
          <p
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.address }}
          </p>
          <p
            v-if="data.value.neighbour"
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.neighbour }}
          </p>
          <p
            v-else
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.neighbour }}
          </p>
        </template>
        <template #cell(field_mls_address_value)="data">
          <span
            v-if="data.item.field_mls_floor_plan_fid != null"
            style="cursor: pointer"
            class="text-primary"
            @click="getFloorPlan(data.item.field_mls_floor_plan_fid)"
            ><u>{{ data.value }}</u></span
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(field_mls_list_price_value)="data">
          <span
            v-b-modal="'mlspop'"
            @click="$emit('popHistory', data.item)"
            :style="{ color: setColor(data.item.price_backup) }"
            >{{ data.value }}</span
          >
        </template>
        <template #cell(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              size="lg"
              variant="danger"
              v-model="data.item.rowSelected"
              @change="promptRow(data.item.rowSelected)"
              class="mr-1 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(architect_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(marketing_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(general_contractor)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <!-- Start of UTC Page -->
        <template #cell(mls_beds)="data">
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-if="data.value == 's'"
            >Studio</strong
          >
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-else
            >{{ data.item.mls_beds }}-bed</strong
          >
          <br />
          {{
            data.item.fp_specs.toLowerCase() != "none" ? data.item.fp_specs : ""
          }}
        </template>
        <template #cell(mls_address)="data">
          <u
            v-if="data.item.mls_floor_plan_feature"
            class="text-primary"
            v-b-modal="'mls-fp-' + data.item.mls_floor_plan_feature.fid"
            >{{ data.value }}</u
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(delete)="data">
          <button
            v-if="user_role == 'admin'"
            @click="$emit('deleteMLS', data.item.cid)"
            class="button border-0"
            style="background: none"
          >
            <font-awesome-icon
              class="text-vb-red _cursor-pointer"
              :icon="['fas', 'trash']"
            />
          </button>

          <button
            v-else
            class="border-0 button"
            style="background: none"
            disabled
          >
            <font-awesome-icon class="text-muted" :icon="['fas', 'trash']" />
          </button>
        </template>
        <template v-if="listing_type" #cell()="data">
          <span
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            >{{ getDefaultValue(data).data }}</span
          >
        </template>
        <template v-else #cell()="data">
          <span v-b-modal="'mlspop'" @click="$emit('popHistory', data.item)">{{
            getDefaultValue(data).data
          }}</span>
        </template>
        <!-- End of UTC Page -->
      </b-table>
    </template>
    <template v-else-if="['projects'].includes(currentTab) || route.currentRoute.path == '/type'">
      <b-table
        :sort-by.sync="pre_sort"
        :sort-compare="sortDate"
        :ref="table_reference"
        :filter-function="filterProjectTable"
        @filtered="onFiltered"
        responsive="sm"
        hover
        small
        :filter-included-fields="filterOn"
        :sort-desc="true"
        striped
        :filter="filter_rows"
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="bg-vb-secondary text-light position-sticky"
        :items="row_data"
        :fields="header_fields"
        >
        <template #head(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              dis
              size="lg"
              @change="onSelectAllRows"
              v-model="all_rows_selected"
              variant="danger"
              class="mr-1 ml-2 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #head(customProjectName)="data">
          {{ data.label }}
          <button @click="$emit('refreshResults')" class="btn btn-light ml-3">UPDATE TABLE</button>
        </template> 
        <template #head(mls_address)="data">
          {{ data.label }}
          <button @click="$emit('refreshResults')" class="btn btn-light ml-3" style="white-space: nowrap;margin: 0 15px;">UPDATE TABLE</button>
        </template>
        <template #cell(mls_list_price)="rowData">
          <span v-if="Number(rowData.value) > 0" 
            v-b-modal="'mlspop'"
            @click="$emit('popHistory', rowData.item)">
                <span :style="{'color' : setColor(['projects'].includes(currentTab) ? rowData.item.price_backup : rowData.item.price_back_up)}">${{ Math.round(rowData.value).toLocaleString() }}</span>
                <template v-if="Number(getPriceChange(rowData.item.price_second, rowData.value).diffPercentage) != 0">
                  (<span class="font-weight-bold" :style="{'color' : getPriceChange(rowData.item.price_second, rowData.value).color}">{{ Number(getPriceChange(rowData.item.price_second, rowData.value).diffPercentage).toLocaleString() }}%</span><font-awesome-icon class="font-weight-bold" :icon="Number(rowData.value) > 0 ? ['fas', getPriceChange(rowData.item.price_second, rowData.value).icon ] : ''" />)                
                </template>
            </span>
            <span v-else>-</span>
        </template> 
        <template #cell(customPlanningData)="data">
          <template
            v-if="
              ['developer', 'developerbc', 'realtor'].includes(
                current_user.role
              )
            "
          >
            <template
              v-for="(value, key) in Object.fromEntries(
                Object.entries(data.value).filter(([k]) =>
                  ['REZ', 'DP'].includes(k)
                )
              )"
            >
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
          <template v-else>
            <template v-for="(value, key) in data.value">
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
        </template>
        <template #cell(project_name)="data">
          <h5 class="text-left pl-3">
            <a
              class="text-vb-primary"
              style="font-weight: 500; font-size: 20px"
              :href="data.item.link"
              >{{ data.value }}
            </a>
          </h5>
        </template>
        <template #cell(customData)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                  <font-awesome-icon
                    :icon="['fas', 'medal']"
                    v-if="isInCurrentQuarter(data.item.sales_start_date)"
                /></a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(customProjectName)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                  <font-awesome-icon
                    :icon="['fas', 'medal']"
                    v-if="
                      ['Selling Soon', 'Leasing Soon'].includes(
                        data.item.status
                      )
                    "
                /></a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(customTypeStoreyData)="data">
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.projectType }}
          </p>
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            ({{ data.value.storey ? data.value.storey : "-" }})
          </p>
        </template>
        <template #cell(customLocationData)="data">
          <p
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.address }}
          </p>
          <p
            v-if="data.value.municipality"
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.municipality }}
          </p>
          <p
            v-else
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.neighbour }}
          </p>
        </template>
        <template #cell(field_mls_address_value)="data">
          <span
            v-if="data.item.field_mls_floor_plan_fid != null"
            style="cursor: pointer"
            class="text-primary"
            @click="getFloorPlan(data.item.field_mls_floor_plan_fid)"
            ><u>{{ data.value }}</u></span
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(field_mls_list_price_value)="data">
          <span
            v-b-modal="'mlspop'"
            @click="$emit('popHistory', data.item)"
            :style="{ color: setColor(data.item.price_backup) }"
            >{{ data.value }}</span
          >
        </template>
        <template #cell(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              size="lg"
              variant="danger"
              v-model="data.item.rowSelected"
              @change="promptRow(data.item.rowSelected)"
              class="mr-1 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(architect_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(marketing_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(general_contractor)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <!-- Start of UTC Page -->
        
        <template #cell(list_price_net)="data">
          <span
          v-b-modal="`mls-${listing_type}-` + data.item.cid"
            >{{ data.value }}</span
          >
        </template>
        <template #cell(mls_beds)="data">
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-if="data.value == 's'"
            >Studio</strong
          >
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-else
            >{{ data.item.mls_beds }}-bed</strong
          >
          <br />
          {{
            data.item.fp_specs.toLowerCase() != "none" ? data.item.fp_specs : ""
          }}
        </template>
        <template #cell(mls_address)="data">
          <u
            v-if="data.item.mls_floor_plan_feature"
            class="text-primary"
            v-b-modal="'mls-fp-' + data.item.mls_floor_plan_feature.fid"
            >{{ data.value }}</u
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(delete)="data">
          <button
            v-if="user_role == 'admin'"
            @click="$emit('deleteMLS', data.item.cid)"
            class="button border-0"
            style="background: none"
          >
            <font-awesome-icon
              class="text-vb-red _cursor-pointer"
              :icon="['fas', 'trash']"
            />
          </button>

          <button
            v-else
            class="border-0 button"
            style="background: none"
            disabled
          >
            <font-awesome-icon class="text-muted" :icon="['fas', 'trash']" />
          </button>
        </template>
        <template v-if="listing_type" #cell()="data">
          <span
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            >{{ getDefaultValue(data).data }}</span
          >
        </template>
        <template v-else #cell()="data">
          <span v-b-modal="'mlspop'" @click="$emit('popHistory', data.item)">{{
            getDefaultValue(data).data
          }}</span>
        </template>
        <!-- End of UTC Page -->
      </b-table>
    </template>
    <template v-else>
      <b-table
        :sort-by.sync="pre_sort"
        :sort-desc="true"
        :sort-compare="sortDate"
        :ref="table_reference"
        @filtered="onFiltered"
        responsive="sm"
        hover
        small
        :filter-included-fields="filterOn"
        striped
        :filter="filter_rows"
        :filter-function="filterMlsTable"
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="bg-vb-secondary text-light position-sticky"
        :items="row_data"
        :fields="header_fields"
      >  
        <template #head(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              dis
              size="lg"
              @change="onSelectAllRows"
              v-model="all_rows_selected"
              variant="danger"
              class="mr-1 ml-2 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #head(customData)="data">
          {{ data.label }}
          <button @click="$emit('refreshResults')" class="btn btn-light ml-3">UPDATE TABLE</button>
        </template> 
        <template #cell(customPlanningData)="data">
          <template
            v-if="
              ['developer', 'developerbc', 'realtor'].includes(
                current_user.role
              )
            "
          >
            <template
              v-for="(value, key) in Object.fromEntries(
                Object.entries(data.value).filter(([k]) =>
                  ['REZ', 'DP'].includes(k)
                )
              )"
            >
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
          <template v-else>
            <template v-for="(value, key) in data.value">
              <p v-if="value" :key="key">{{ key }} : {{ value }}</p>
            </template>
          </template>
        </template>
        <template #cell(project_name)="data">
          <h5 class="text-left pl-3">
            <a
              class="text-vb-primary"
              style="font-weight: 500; font-size: 20px"
              :href="data.item.link"
              >{{ data.value }}
            </a>
          </h5>
        </template>
        <template #cell(customData)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                  <font-awesome-icon
                    :icon="['fas', 'medal']"
                    v-if="isInCurrentQuarter(data.item.sales_start_date)"
                /></a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(customProjectName)="data">
          <div class="row justify-content-between pl-2 pr-5">
            <div>
              <h5 class="text-left pl-3">
                <a
                  class="text-vb-primary"
                  style="font-weight: 500; font-size: 20px"
                  :href="data.value.href_link"
                  >{{ data.value.projectName }}
                  <font-awesome-icon
                    :icon="['fas', 'medal']"
                    v-if="
                      ['Selling Soon', 'Leasing Soon'].includes(
                        data.item.status
                      )
                    "
                /></a>
              </h5>
              <p
                class="text-left pl-3 text-vb-primary mb-0 pb-0"
                style="font-weight: 400; font-size: 18px"
              >
                {{ data.value.project }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(customTypeStoreyData)="data">
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.projectType }}
          </p>
          <p
            class="pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            ({{ data.value.storey ? data.value.storey : "-" }})
          </p>
        </template>
        <template #cell(customLocationData)="data">
          <p
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.address }}
          </p>
          <p
            v-if="data.value.municipality"
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.municipality }}
          </p>
          <p
            v-else
            class="text-left pl-3 text-vb-primary mb-0 pb-0"
            style="font-weight: 400; font-size: 18px"
          >
            {{ data.value.neighbour }}
          </p>
        </template>
        <template #cell(field_mls_address_value)="data">
          <span
            v-if="data.item.field_mls_floor_plan_fid != null"
            style="cursor: pointer"
            class="text-primary"
            @click="getFloorPlan(data.item.field_mls_floor_plan_fid)"
            ><u>{{ data.value }}</u></span
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(field_mls_list_price_value)="rowData">
          <span v-if="Number(rowData.value) > 0" 
            v-b-modal="'mlspop'"
            @click="$emit('popHistory', rowData.item)">
                <span :style="{'color' : setColor(rowData.item.price_backup)}">${{ Math.round(rowData.value).toLocaleString() }}</span>
                <template v-if="Number(getPriceChange(rowData.item.price_second, rowData.value).diffPercentage) != 0">
                  (<span class="font-weight-bold" :style="{'color' : getPriceChange(rowData.item.price_second, rowData.value).color}">{{ Number(getPriceChange(rowData.item.price_second, rowData.value).diffPercentage).toLocaleString() }}%</span><font-awesome-icon class="font-weight-bold" :icon="Number(rowData.value) > 0 ? ['fas', getPriceChange(rowData.item.price_second, rowData.value).icon ] : ''" />)                
                </template>
            </span>
            <span v-else>-</span>
        </template> 
        <template #cell(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              size="lg"
              variant="danger" 
              v-model="data.item.rowSelected"
              @change="promptRow(data.item.rowSelected)"
              class="mr-1 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(architect_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(marketing_consultant)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(general_contractor)="data">
          <h4 v-if="data.value.length > 0">
            <BBadge
              variant="vb-secondary"
              class="m-1"
              v-for="(item, index) in data.value"
              :key="index"
            >
              {{ item }}
            </BBadge>
          </h4>
          <p v-else>-</p>
        </template>
        <template #cell(custom_field_mls_beds_value)="data">
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            >{{ data.value.type }}</strong
          >
          <br />
          {{ data.value.fp_specs }}
        </template>
        <!-- Start of UTC Page -->
        <template #cell(mls_beds)="data">
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-if="data.value == 's'"
            >Studio</strong
          >
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-else
            >{{ data.item.mls_beds }}-bed</strong
          >
          <br />
          {{
            data.item.fp_specs.toLowerCase() != "none" ? data.item.fp_specs : ""
          }}
        </template>
        <template #cell(mls_address)="data">
          <u
            v-if="data.item.mls_floor_plan_feature"
            class="text-primary"
            v-b-modal="'mls-fp-' + data.item.mls_floor_plan_feature.fid"
            >{{ data.value }}</u
          >
          <span v-else>{{ data.value }}</span>
        </template>

        <template #cell(custom_net_psf)="data">
          ${{ Number(data.value).toLocaleString() }}
        </template>

        <template #cell(delete)="data">
          <button
            v-if="user_role == 'admin'"
            @click="$emit('deleteMLS', data.item.cid)"
            class="button border-0"
            style="background: none"
          >
            <font-awesome-icon
              class="text-vb-red _cursor-pointer"
              :icon="['fas', 'trash']"
            />
          </button>

          <button
            v-else
            class="border-0 button"
            style="background: none"
            disabled
          >
            <font-awesome-icon class="text-muted" :icon="['fas', 'trash']" />
          </button>
        </template>
        <template v-if="listing_type" #cell()="data">
          <span
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            >{{ getDefaultValue(data).data }}</span
          >
        </template>
        <template v-else #cell()="data">
          <span v-b-modal="'mlspop'" @click="$emit('popHistory', data.item)">{{
            getDefaultValue(data).data
          }}</span>
        </template>
        <!-- End of UTC Page -->
      </b-table>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onMounted, watch  } from "vue";
import Vue from "vue";
import { BModal, VBModal, VBTooltip } from "bootstrap-vue";
import { useRouter } from "vue2-helpers/vue-router";
import { useUserStore } from "../../store/UserStore";
import { BBadge } from "bootstrap-vue";
import {ROOT_URL} from '../../variables.js';

// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
const route = useRouter();

Vue.directive("b-modal", VBModal);
Vue.directive("b-tooltip", VBTooltip);

// const root = getCurrentInstance(); // same as ctx.root in component

const emit = defineEmits([
  "popModal",
  "updateTotalRows",
  "selectAllRows",
  "popHistory",
  "countProjectSelected",
  "getFilteredData"
]);

const props = defineProps({
  row_data: [],
  header_fields: [],
  perPage: null,
  currentPage: null,
  filter: null,
  totalRows: null,
  listing_type: null,
  user_role: null,
  table_reference: null,
  filterOn: [],
  project_status: null,
  filtered_data:[],
  marketRentalPsfToDouble: null,
  filter_rows: null,
  currentTab: null,
});

const current_user = ref(useUserStore().current_user);
const pre_sort = ref("");
const model_totalrows = computed({
  get: () => props.totalRows,
  set: (newVal) => {
    emit("updateTotalRows", newVal);
  },
});

const sortDate = (
  a,
  b,
  key,
  sortDesc,
  formatter,
  compareOptions,
  compareLocale
) => {
  const aDate = new Date(a[key]);
  const bDate = new Date(b[key]); 
  if (
    [
      "sales_start_date",
      "mls_price_date",
      "price_date",
      "rz_sub_date",
      "dp_sub_date",
      "sold_date",
      "updated",
      "occupancy_date",
      "mls_sold_date"
    ].includes(key)
  ) {
    if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
      let aToNum = aDate.getTime();
      let bToNum = bDate.getTime();

      return aToNum < bToNum ? -1 : aToNum > bToNum ? 1 : 0;
    }
    if (sortDesc) {
      if (isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) return -1;

      if (isNaN(bDate.getTime()) && !isNaN(aDate.getTime())) return 1;

      return 0;
    } else {
      if (isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) return 1;

      if (isNaN(bDate.getTime()) && !isNaN(aDate.getTime())) return -1;

      return 0;
    }
  } else {

    if(["customConstructionStatus","construction_status"].includes(key)) {
      const dataA = a[key];
      const dataB = b[key];
      const customOrder = {
              '': 0,
              'Pre-Construction': 1,
              'In Process': 2,
              "Excavation & Foundation": 3,
              "Framing": 4,
              "Interior Finishing": 5,
              'Some Homes Complete': 6,
              'Complete': 7,
            }  

      if (customOrder[dataA] != undefined && customOrder[dataB] !== undefined)
      { 
        return customOrder[dataA] < customOrder[dataB] ? -1 : customOrder[dataA] > customOrder[dataB] ? 1 : 0;
      }
      if (sortDesc) {
        if (customOrder[dataA] === undefined && customOrder[dataB] !== undefined) return -1;

        if (customOrder[dataB] === undefined && customOrder[dataA] !== undefined) return 1;

        return 0;
      } else {
        if (customOrder[dataA] === undefined && customOrder[dataB] !== undefined) return 1;

        if (customOrder[dataB] === undefined && customOrder[dataA] !== undefined) return -1;

        return 0;
      }
    }

    if (["customRz", "customDp", "b"].includes(key)) {  

      const dataA = a[key];
      const dataB = b[key];

      const customOrder =
        key == "customRz"
          ? {
              Adopted: 0,
              Approved: 1,
              "Second Reading": 2,
              "First Reading": 3,
              "In Process": 4,
              Submitted: 5,
              "N/A": 6,
            }
          : {
              "Not Submitted": 0,
              Submitted: 1,
              "In Process": 2,
              Approved: 3,
              Issued: 4,
              Completed: 5,
            };

      if (customOrder[dataA] != undefined && customOrder[dataB] !== undefined)
        return customOrder[dataA] - customOrder[dataB];
      else
        return -1;
    }
  }
};

const getDefaultValue = (data) => {
  let result = {
    data: "",
    color: "",
  };
  if ((data.value.length <= 0 || data.value == null) && !['net_psf'].includes(data.field.key)) {
    return (result = {
      data: "-",
      color: "",
    });
  }

  if (
    [
      "total_avg_price",
      "mls_list_price",
      "net_price",
      "buyer_incentives",
      "realtor_incentives",
      "total_avg_psf",
    ].includes(data.field.key)
  ) {
    if (!isNaN(data.value) && parseInt(data.value) > 0) {
      if (
        route.currentRoute.path == "/listing-new" &&
        route.currentRoute.query["status[]"] == "now_leasing" &&
        ["developer", "developerbc"].includes(current_user.value.role)
      ) {
        return (result = {
          data:
            "$" +
            Number(data.value).toFixed(2),
          color: setColor(data.item.price_back_up),
        });
      } else {
        return (result = {
          data: "$" + Math.round(parseInt(data.value)).toLocaleString("en-US"),
          color: setColor(data.item.price_back_up),
        });
      }
    }

    return (result = {
      data: "-",
      color: "-",
    });
  }

  if (["psf"].includes(data.field.key)) {
    if (
      props.marketRentalPsfToDouble &&
      props.marketRentalPsfToDouble != null
    ) {
      return (result = {
        data: "$" + Number(data.value).toFixed(2),
        color: setColor(data.item.price_back_up),
      });
    }

    return (result = {
      data: "$" + Number(Math.round(data.value)),
      color: setColor(data.item.price_back_up),
    });
  }

  if (['net_psf'].includes(data.field.key)) {
        if ((!isNaN(data.value) || parseInt(data.value) > 0 || data.value.length > 0)) { 
          
            if(props.project_status != null && ['now selling','now leasing'].includes(props.project_status.toLowerCase()))
            {  
                return result = {
                    data: '$' + Math.round(Number(data.item.net_price) / Number(data.item.mls_unit_size)).toLocaleString(),
                    color: setColor(data.item.price_back_up)
                }  
            }
            if(route.currentRoute.path == '/unit-mix')
            {  
              if(Number(data.item.net_price) && Number(data.item.mls_unit_size)) {
                return result = {
                    data: '$' + Number(Number(data.item.net_price / data.item.mls_unit_size)).toFixed(2),
                    color: setColor(data.item.price_back_up)
                } 
              }
              return result = {
                  data: '-',
                  color: '-'
              }
            }  
        }

        return result = {
            data: '-',
            color: '-'
        }
    }

  if (["mls_psf"].includes(data.field.key)) {
    let list_price = Number(data.item.mls_list_price);
    let unit_size = Number(data.item.mls_unit_size);
    if (
      !isNaN(data.value) ||
      parseInt(data.value) > 0 ||
      data.value.length > 0
    ) {
      if (
        props.project_status != null &&
        ["now selling"].includes(props.project_status.toLowerCase())
      ) {
        if (typeof data.value == "string" && data.value.includes("$")) {
          return (result = {
            data: data.value,
            color: setColor(data.item.price_back_up),
          });
        } else {
          return (result = {
            data: data.value
              ? "$" + Math.round(parseInt(data.value)).toLocaleString("en-US")
              : "-",
            color: setColor(data.item.price_back_up),
          });
        }
      } else if (
        route.currentRoute.path == "/type" &&
        props.project_status &&
        props.project_status.toLowerCase() === "now leasing" &&
        props.listing_type === "sold"
      ) {
        if (
          typeof data.value == "string" &&
          data.value.includes("$") &&
          data.value.length > 0 &&
          Number(list_price) > 0
        ) {
          return (result = {
            data: "$" + Number(list_price / unit_size).toFixed(2),
            color: setColor(data.item.price_back_up),
          });
        }

        return (result = {
          data: "-",
          color: setColor(data.item.price_back_up),
        });
      } 
      else if (route.currentRoute.path == "/unit-mix") {
        if(Number(data.item.mls_list_price) > 0 && Number(data.item.mls_unit_size) > 0) { 
          return (result = {
            data: "$" + Number(list_price / unit_size).toFixed(2),
            color: setColor(data.item.price_back_up),
          });
        }
        return (result = {
            data: "-",
            color: setColor(data.item.price_back_up),
          });
      }
      else {
        if (typeof data.value == "string" && data.value.includes("$")) {
          return (result = {
            data: data.value,
            color: setColor(data.item.price_back_up),
          });
        }
        return (result = {
          data: "$" + Number(data.value).toFixed(2),
          color: setColor(data.item.price_back_up),
        });
      }
    } 

    return (result = {
      data: "-",
      color: "-",
    });
  }

  return (result = {
    data: data.value,
    color: "",
  });
};

const all_rows_selected = ref(false);

const getFloorPlan = (data) => {
  emit("popModal", data);
};

const isInCurrentQuarter = (date) => {
  const now = new Date();
  const currentQuarter = Math.floor(now.getMonth() / 3) + 1;
  const salesDate = new Date(date);
  const salesQuarter = Math.floor(salesDate.getMonth() / 3) + 1;

  return (
    salesDate.getFullYear() === now.getFullYear() &&
    salesQuarter === currentQuarter
  );
};
const setColor = (data) => {
  let color = "";
  if (data) {
    switch (data.toLocaleLowerCase()) {
      case "verified":
        color = "#0b36dc";
        break;

      case "educated estimated":
        color = "#dc3545";
        break;

      case "semi-verified":
        color = "#FF6F16";
        break;

      default:
        color = "#000";
        break;
    }
  }
  return color;
};

const onFiltered = (filteredItems) => {
  model_totalrows.value = filteredItems.length;
};
const selectableTable = ref(null);

const onSelectAllRows = () => {
  emit("selectAllRows", {
    selectAll: all_rows_selected.value,
    tableRef: props.table_reference,
  });
};

const promptRow = (data) => {
  emit("countProjectSelected", data);
};
const value = ref(null);

const containsString = (obj, searchString) => {
  return Object.values(obj).some((value) => {
    return String(value).toLowerCase().includes(searchString.toLowerCase());
  });
};



const filterMlsTable = (row, criteria) => {
  let bath = new Array ()
  let type = new Array ()
  let fp = new Array ()
  let status = new Array ()
  let cs = new Array ()
  let projectType = new Array ()
  let productType = new Array ()

  const matchesCriteria = (bathCheck, typeCheck, fpCheck, statusCheck, csCheck, productTypeCheck, projectTypeCheck) => {
    return (
      (!bathCheck || bath.includes(row.field_mls_baths_value)) && 
      (!typeCheck || type.includes(row.custom_field_mls_beds_value.type)) && 
      (!fpCheck || fp.some(item => row.fp_specs.includes(item))) && 
      (!statusCheck || status.some(item => row.status_name_name.includes(item))) && 
      (!csCheck || cs.some(item => row.construction_status.includes(item))) && 
      (!productTypeCheck || productType.includes(row.product_type)) && 
      (!projectTypeCheck || projectType.includes(row.project_type)) && 
      containsString(row, criteria.filterText)
    );
  };
    criteria.filterArray.forEach((val) => {
      if (val.startsWith("bath_")) bath.push(val.replace("bath_", ""));
      if (val.startsWith("bed_")) type.push(val.replace("bed_", ""));
      if (val.startsWith("fp_")) fp.push(val.replace("fp_", ""));
      if (val.startsWith("st_")) status.push(val.replace("st_", ""));
      if (val.startsWith("cs_")) cs.push(val.replace("cs_", ""));
      if (val.startsWith("pt_")) projectType.push(val.replace("pt_", ""));
      if (val.startsWith("pdt_")) productType.push(val.replace("pdt_", ""));
    });

   return criteria.filterArray.length > 0
    ? matchesCriteria(bath.length > 0, type.length > 0, fp.length > 0, status.length > 0, cs.length > 0, productType.length > 0, projectType.length > 0)
    : containsString(row, criteria.filterText);
  
};

const filterProjectTable = (row,  criteria) => {
  
  let projectType = new Array ()
  let productType = new Array ()
  let constructionStatus = new Array ()
  let neighbourhood = new Array ()
  const matchesCriteria = (projectTypeCheck, productTypeCheck, constructionStatusCheck, neighbourhoodCheck) => {
    return (
      (!projectTypeCheck || projectType.includes(row.project_type)) && 
      (!productTypeCheck || productType.includes(row.product_type)) && 
      (!constructionStatusCheck || constructionStatus.includes(row.construction_status)) && 
      (!neighbourhoodCheck || neighbourhood.includes(row.city)) && 
      containsString(row, criteria.filterText)
    );
  };

  criteria.filterArray.forEach((val) => {
    if (val.startsWith("pt_")) projectType.push(val.replace("pt_", ""));
    if (val.startsWith("pdt_")) productType.push(val.replace("pdt_", ""));
    if (val.startsWith("cs_")) constructionStatus.push(val.replace("cs_", ""));
    if (val.startsWith("nh_")) neighbourhood.push(val.replace("nh_", ""));
  });

   return criteria.filterArray.length > 0
    ? matchesCriteria(projectType.length > 0, productType.length > 0, constructionStatus.length > 0, neighbourhood.length > 0)
    : containsString(row, criteria.filterText);
}

const filterTable = (row, criteria) => {
  let dp = new Array();
  let rz = new Array();
  let pt = new Array();
  let cs = new Array()
  const matchesCriteria = (ptCheck, dpCheck, rzCheck, csCheck) => {
    return (
      (!ptCheck || pt.includes(row.type)) && 
      (!dpCheck || dp.includes(row.dp)) && 
      (!rzCheck || rz.includes(row.rz)) && 
      (!csCheck || cs.includes(row.construction_status)) && 
      containsString(row, criteria.filterText)
    );
  };

  criteria.filterArray.forEach((val) => {
    if (val.startsWith("pt_")) pt.push(val.replace("pt_", ""));
    if (val.startsWith("dp_")) dp.push(val.replace("dp_", ""));
    if (val.startsWith("cs_")) cs.push(val.replace("cs_", ""));
    if (val.startsWith("rz_")) rz.push(val.replace("rz_", ""));
  });

  
  return criteria.filterArray.length > 0
    ? matchesCriteria( pt.length > 0,dp.length > 0, rz.length > 0, cs.length > 0)
    : containsString(row, criteria.filterText);
}; 


const filtered_mls_data = computed( () => {
  return props.row_data.filter(item => { 

    if(route.currentRoute.path == '/mls-list')
      return filterMlsTable(item, props.filter_rows)

    if(props.currentTab == 'planning')
      return filterTable(item, props.filter_rows)

    if(props.currentTab == 'projects')
      return filterProjectTable(item, props.filter_rows)

      return
  })
})

const getPriceChange = (oldValue, newValue) => {
    let result = {
        diffPercentage: '',
        color: '',
        icon: ''
    }

    if(newValue == '-'|| !oldValue)
        return result = {
            diffPercentage: '',
            color: '',
            icon: ''
        }

 
    result.diffPercentage = Number(((newValue - oldValue) / oldValue) * 100).toFixed(2);
    if(Number(result.diffPercentage) > 0) {
        result.color = 'green'
        result.icon = "fa-long-arrow-alt-up"
    } else if (Number(result.diffPercentage) < 0) {
        result.color = 'red'
        result.icon = "fa-long-arrow-alt-down"
    } else {
        result.color = 'black'
        result.icon = "minus"
    }

    
    return result
}

watch(filtered_mls_data, (newVal) => {
    emit("getFilteredData", newVal)
}) 
onMounted(() => {
  if(route.currentRoute.path == "/type") {
    pre_sort.value =
    route.currentRoute.path == "/type" && props.listing_type == 'sold' ? 'mls_sold_date' : "mls_price_date";
  } else {
    pre_sort.value = "price_date"
  }
 
});
</script>

<style scoped></style>
