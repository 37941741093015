<template>
    <div>
        <b-table :fields="headerFields" :items="reportDetailSummary" responsive="sm" hover small thead-class="bg-vb-secondary text-light position-sticky">
            <template #cell(type)="data">
                <strong
                    class="font-weight-bold"
                    v-if="data.value.mls_beds == 's'"
                    >Studio</strong
                >
                <strong
                    class="font-weight-bold"
                    v-else-if="data.value.mls_beds == 'jr'"
                    >Jr 1-bed</strong
                >
                <strong
                    class="font-weight-bold"
                    v-else
                    >{{ data.value.mls_beds }}-bed</strong
                >
                <br />
                {{
                    data.value.fp_specs.toLowerCase() != "none" ? data.value.fp_specs : ""
                }}
            </template> 
            <template #cell(units_percentage)="data">
                <span v-if="data.value != '-'"
                    >{{data.value}}%</span
                >
                <span v-else
                    >{{data.value}}</span
                >  
            </template>

        </b-table>
    </div>
</template>

<script lang="ts" setup> 

const props = defineProps({
    reportDetailSummary: null,
    headerFields: null
})
</script>

<style scoped>

</style>