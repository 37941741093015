import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './routes'
import jQuery from "jquery";
import Slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
import VueLodash from 'vue-lodash';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
// import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import { createPinia, PiniaVuePlugin } from 'pinia'  
import regeneratorRuntime from "regenerator-runtime";
require('./assets/styles/font.css')
require('./assets/styles/reset.css')
require('./assets/styles/color.css')
// require('./assets/styles/date-droop-color.css')
require('./assets/styles/plugins.css')
require('./assets/styles/index.css')
require('./assets/styles/style.css')
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* import the fontawesome core */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueCookies from 'vue-cookies'
import { InputGroupPlugin, FormSpinbuttonPlugin , ModalPlugin , BRow, BCol, ToastPlugin, FormGroupPlugin , FormCheckboxPlugin, PaginationPlugin , TablePlugin , SidebarPlugin, CardPlugin, NavbarPlugin, BDropdown, BDropdownItem, BDropdownDivider, BDropdownText, BFormSelect, BFormSelectOption, BCollapse, BProgress, BProgressBar, BButton, BFormInput, BNavBar, NavPlugin, BForm  } from 'bootstrap-vue';

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(fas);
library.add(far);
library.add(fab);
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-text', BDropdownText);
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-select-option', BFormSelectOption)
Vue.component('b-collapse', BCollapse)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-progress', BProgress);
Vue.component('b-progress-bar', BProgressBar) 
Vue.component('b-button', BButton)
Vue.component('b-form-input', BFormInput)
Vue.component('b-col', BCol);
Vue.component('b-row', BRow);
Vue.component('b-form', BForm)

Vue.config.productionTip = false

Vue.use(VueRouter, BootstrapVue, VueLodash, NavbarPlugin);
Vue.use(VueCookies);
Vue.use(SidebarPlugin);
Vue.use(NavPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ToastPlugin);
Vue.use(CardPlugin);
Vue.use(ModalPlugin );
Vue.use(FormSpinbuttonPlugin);
Vue.use(InputGroupPlugin);

Vue.$cookies.config('7d')
Vue.filter('numFormat', numFormat(numeral));

const pinia = createPinia();
Vue.use(pinia);
Vue.use(PiniaVuePlugin)  
 
Vue.use(VueGtag, {
  config: { id: "G-NDVH0056TS" }
});
const vm = new Vue({
    el: '#app',
    router,
    render: h => h(App),
    components: { Slick, jQuery},
    pinia
})
