<template>
    <section class="justify-content-center" style="padding:0 10rem;height: max-content; min-height: 100%;">
        <b-modal ref="summary-table-modal" title="Unit Preview" ok-only> 
            <div class="row justify-content-center mt-2 mb-3"> 
                <template v-for="(item, index, key) in totalListingSummary"> 
                    <h4 :key="index" class="bg-vb-primary mx-2 p-2 rounded text-light" v-if="item[Object.keys(item)] > 0">
                        {{ Object.keys(item)[0] }} <b-badge variant="light">{{ item[Object.keys(item)] }}</b-badge>
                    </h4>
                </template>
            </div>
            <SummaryTable :report-detail-summary="unitPreviewSummary" :header-fields="unitPreviewSummaryHeader"/>
        </b-modal>
        <div class="row py-3">
            <div class="col-md-12">
                <template>
                    <div class="row mt-4 ml-1 justify-content-end align-items-center mb-3"> 
                        <div class="d-flex">
                            <span class="text-white p-2 mx-2 _price-backup" style="background-color:#0b36dc">Verified</span>
                            <span class="text-white p-2 mx-2 _price-backup" style="background-color:#FF6F16">Semi-Verified</span>
                            <span class="text-white bg-danger p-2 mx-2 _price-backup">Estimated</span>
                        </div>
                    </div>
                    
                    <div class="fields-toggle-wrapper" v-if="custom_header_field">
                        <div class="fields-toggle-block selected-fields">
                            <div class="row justify-content-between">
                                <div class="fields-toggle-title">Shown Data Point</div>
                                <font-awesome-icon
                                    style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                    class="text-danger" @click="custom_header_field = false" :icon="['fas', 'times-circle']" />
                            </div>
                            <draggable class="row" :list="rpdTableList1" group="field"
                                @change="fieldsOn" :sort="true"> 
                            <template v-for="(element, index) in rpdTableList1" >
                                <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                >
                                {{ index }}. {{ element.label }}</div>
                            </template> 
                            </draggable>
                        </div>
                        <div class="fields-toggle-bar"></div>
                        <div class="fields-toggle-block">
                            <div class="fields-toggle-title">Drag and Drop Data Points</div>
                            <draggable class="row" :list="rpdTableList2" group="field" :sort="true">
                            
                                <template v-for="(element, index) in rpdTableList2" >
                                <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                >
                                {{ element.label }}</div>
                            </template> 
                            </draggable>
                        </div>
                    </div>
                    <div>
                        <b-tabs content-class="mt-3">
                            <b-tab title="Active" :active="listingTab == 'active'" @click="listingTab = 'active', setRpdTableHeader()" v-if="mlsActiveSort != null"> 
                                <div class="row justify-content-between mb-4">
                                    <h1 class="text-left">Active Listings</h1>
                                    <div class="row">
                                        <b-button variant="vb-primary" @click="showUnitPreview" pill>Unit Preview</b-button> 
                                        <img class="_field_icon mx-2 mt-10" src="/images/assets/fields.png" @click="custom_header_field = !custom_header_field"/>
                                    </div>
                                </div>
                                <template> 
                                    
                                    <UnitMixTable
                                            id="new-active-listing-table" 
                                            v-model="totalRows.active" 
                                            :totalRows="totalRows.active" 
                                            :current-page="currentPage.active"
                                            :per-page="perPage"
                                            :listing_type="'sold'"
                                            :table_reference="'sold-table'"
                                            :row_data="mlsActiveSort" 
                                            :header_fields="rpdTableList1"
                                            v-on:selectAllRows="selectAllRows"
                                            v-on:refreshResults="refreshResults"
                                        /> 
                                    
                                    <div class="row justify-content-between">
                                        <div style="margin-bottom: 8rem !important;">
                                            <b-pagination
                                                v-model="currentPage.active"
                                                :total-rows="totalRows.active"
                                                :per-page="perPage"
                                                prev-text="Prev"
                                                first-text="First"  
                                                last-text="Last"
                                                size="md"
                                                >
                                            <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                                            <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                                            </b-pagination> 
                                        </div>
                                                
                                        <div class="px-3"> 
                                            <b-form-select v-model="perPage" :options="rowOptions" class="mt-15"></b-form-select>
                                        </div>
                                    </div>
                                </template>
                            </b-tab>
                            <b-tab title="Sold" :active="listingTab == 'sold'" @click="listingTab = 'sold', setRpdTableHeader()"  v-if="mlsSoldSort != null">
                                <template>
                                    <div class="row justify-content-between mb-4">
                                        <h1 class="text-left">Sold Listings</h1>
                                        <div class="row">
                                            <b-button variant="vb-primary" @click="showUnitPreview" pill>Unit Preview</b-button> 
                                            <img class="_field_icon mx-2 mt-10" src="/images/assets/fields.png" @click="custom_header_field = !custom_header_field"/>
                                        </div>
                                    </div>  
                                    <template> 
                                        <UnitMixTable
                                            id="new-sold-listing-table" 
                                             v-model="totalRows.sold" 
                                            :totalRows="totalRows.sold" 
                                            :current-page="currentPage.sold"
                                            :per-page="soldPerPage"
                                            :listing_type="'sold'"
                                            :table_reference="'sold-table'"
                                            :row_data="mlsSoldSort" 
                                            :header_fields="rpdTableList1"
                                            v-on:selectAllRows="selectAllRows"
                                            v-on:refreshResults="refreshResults"
                                        /> 
                                        
                                        <div class="row justify-content-between">
                                            <div style="margin-bottom: 8rem !important;">
                                                <b-pagination
                                                    v-model="currentPage.sold"
                                                    :total-rows="totalRows.sold"
                                                    :per-page="soldPerPage"
                                                    prev-text="Prev"
                                                    first-text="First"  
                                                    last-text="Last"
                                                    size="md"
                                                    >
                                                <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                                                <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                                                </b-pagination> 
                                            </div>
                                                    
                                            <div class="px-3"> 
                                                <b-form-select v-model="soldPerPage" :options="rowOptions" class="mt-15"></b-form-select>
                                            </div>
                                        </div>
                                        
                                    </template> 
                                   
                                    <b-modal v-for="(d, k) in project.mls_last_date_sold" :id="'mls-fp-'+d.mls_floor_plan_feature.fid" hide-footer :key="k">
                                        <div v-if="d.mls_floor_plan_feature.fid" class="col-md-4 d-block text-center">
                                            <a target="_blank" :href="d.mls_floor_plan_feature.link"><img class='floorplan-pdf' :src="d.mls_floor_plan_feature.preview"></a>
                                        </div>
                                    </b-modal>
                                    <b-modal v-for="(v_o, k_o) in project.mls_data" :id="'mls-sold-'+k_o" hide-footer :key="k_o" title="Sold Listing Summary">
                                        <template>
                                            <SummaryPopUp 
                                            :data="v_o" 
                                            :mlsHistory="v_o" 
                                            :listing_type="'sold'"
                                            :project_status="'none'" 
                                            v-on:set-project-id="cidSelected = $event" v-on:editMLSEntryNew="editMLSEntry" v-on:deleteMLSEntry="deleteMLSEntry" v-on:showBackupTextModal="showBackupTextModal"/>
                                        </template>
                                    </b-modal>
                                    <b-modal id="mls-entry-new" hide-footer>
                                        <b-form v-on:submit.prevent="mlsEntryUpload" class="text-left">
                                            <b-form-group id="mls-input-group-status" label="Status:" label-for="mls-upload-status">
                                                <b-form-select id="mls-upload-status" v-model="MLSuploadForm.status" :options="mlsStatus"></b-form-select>
                                            </b-form-group>
                                            <b-form-group id="mls-input-group-list-price" label="List Price:" label-for="mls-upload-list-price">
                                                <b-form-input v-model="MLSuploadForm.listPrice" id="mls-upload-list-price" type="text" placeholder="List Price"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="input-group-price-backup" label="Price Backup:" label-for="upload-price-backup">
                                                <b-form-select id="upload-price-backup" v-model="MLSuploadForm.priceBackup" :options="priceBackupOption"></b-form-select>
                                            </b-form-group>
                                            <label for="upload-list-date" style="font-size: 1.8rem;">List Price Date</label>
                                            <b-form-datepicker id="mls-upload-list-price-date" v-model="MLSuploadForm.listPriceDate" reset-button class="mb-2"></b-form-datepicker>
                                            <label for="upload-list-date" style="font-size: 1.8rem;">Sold Date</label>
                                            <b-form-datepicker id="mls-upload-sold-date" v-model="MLSuploadForm.soldDate" reset-button class="mb-2"></b-form-datepicker>
                                            <label for="upload-backup-file" style="font-size: 1.8rem;">Back-up File:</label>
                                            <b-form-group id="mls-input-group-5">
                                                <b-form-file
                                                    v-model="MLSuploadForm.floorPlan"
                                                    :state="Boolean(MLSuploadForm.floorPlan)"
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                                    ></b-form-file>
                                            </b-form-group>
                                            <b-form-group style="margin-top: 40px;" id="mls-input-group-backup-text" label="Back-up Text:" label-for="mls-upload-backup-text">
                                                <b-form-input v-model="MLSuploadForm.backupText" id="mls-upload-backup-text" type="text" placeholder="Backup Text"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="mls-input-group-buyer-incentives" label="Buyer Incentives:" label-for="mls-upload-buyer-incentives">
                                                <b-form-input v-model="MLSuploadForm.buyerIncentives" id="mls-upload-buyer-incentives" type="text" placeholder="Buyer Incentives"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="mls-input-group-realtor-incentives" label="Realtor Incentives:" label-for="mls-upload-realtor-incentives">
                                                <b-form-input v-model="MLSuploadForm.realtorIncentives" id="mls-upload-realtor-incentives" type="text" placeholder="Realtor Incentives"></b-form-input>
                                            </b-form-group>
                                            <b-button type="submit" variant="primary">Submit</b-button>
                                        </b-form>
                                    </b-modal>
                                </template>
                            </b-tab>
                        </b-tabs>
                    </div>
                  
                    <b-modal v-for="(d, k) in project.mls_last_date_active" :id="'mls-fp-'+d.mls_floor_plan_feature.fid" hide-footer :key="k">
                        <div v-if="d.mls_floor_plan_feature.fid" class="col-md-4 d-block text-center">
                            <a target="_blank" :href="d.mls_floor_plan_feature.link"><img class='floorplan-pdf' :src="d.mls_floor_plan_feature.preview"></a>
                        </div>
                    </b-modal>
                    <b-modal v-for="(v_o, k_o) in project.mls_data" :id="'mls-active-'+k_o" hide-footer :key="k_o" title="Active Listing Summary">
                        <template>
                            <SummaryPopUp 
                            :data="v_o" 
                            :mlsHistory="v_o" 
                            :listing_type="'active'"
                            :project_status="'none'" 
                            v-on:set-project-id="cidSelected = $event" v-on:editMLSEntryNew="editMLSEntry" v-on:deleteMLSEntry="deleteMLSEntry" v-on:showBackupTextModal="showBackupTextModal"/>
                        </template>
                    </b-modal>
                </template>
            </div>
        </div>
    </section>
</template>
<script>
    import {BACKEND_URL} from '../variables.js';
    import {BModal, BModalDirective, VBModal, BForm, BFormGroup, 
    BFormInput, BFormSelect, BFormCheckbox, BFormCheckboxGroup,  BButton, BFormFile, BFormDatepicker, BBadge} from 'bootstrap-vue';
    import { useUserStore } from '../store/UserStore'; 
    import RpdTable from './common/RpdTable.vue'; 
    import {mapStores, mapState} from 'pinia' 
    import draggable from 'vuedraggable'; 
    import SummaryPopUp from './common/SummaryPopUp.vue';
    import SummaryTable from './common/SummaryTable.vue';
    import { BTabs, BTab } from 'bootstrap-vue'
    import UnitMixTable from './RpdTables/UnitMixTable.vue'
    export default {
        components: { 
            'b-modal': BModal,
            'b-form': BForm,
            'b-form-group': BFormGroup,
            'b-form-input': BFormInput,
            'b-form-select': BFormSelect,
            'b-form-checkbox': BFormCheckbox,
            'b-form-checkbox-group': BFormCheckboxGroup,
            'b-button': BButton,
            'b-form-file': BFormFile,
            'b-form-datepicker': BFormDatepicker,
            'b-tabs': BTabs,
            'b-tab': BTab,
            'b-badge': BBadge,
            RpdTable,
            UnitMixTable,
            SummaryPopUp,
            SummaryTable,
            draggable
        },
        directives: {
            'b-modal': VBModal,
        },
        data() {
            return {
                listingTab:'active',
                unitPreviewSummaryHeader : [ 
                    { key: 'mls_plan_name', label: 'Plan Name' ,tdClass:'border-right-0', sortable: true },
                    { key: 'type', label: 'Type', sortable: true },
                    { key: 'baths', label: 'Baths', sortable: true },
                    { key: 'units', label: 'Total Units', sortable: true },
                    { key: 'confirmed_sold', label: 'Confirmed Sold', formatter: (value, row, key)  => { return value ? value : '-'},sortable: true },
                    { key: 'sold_percentage', label: 'Sold Percentage', formatter: (value, row, key)  => {                       
                        return key.confirmed_sold > 0 ? `${Math.round(Number((key.confirmed_sold / key.units) * 100))}%` : '-'
                    },sortable: true },
                ],
                rowOptions: [
                { value: 10, text: '10 rows' }, 
                { value: 25, text: '25 rows' }, 
                { value: 50, text: '50 rows' }, 
                { value: 100, text: '100 rows' }, 
                ],
                custom_header_field:false,
                backupModalText:'',
                rpdTableList1:[],
                rpdTableList2:[],
                totalRows:  {
                    active: 1,
                    sold: 1,
                }, 
                currentPage: {
                    active: 1,
                    sold: 1,
                },
                perPage: 10,
                soldPerPage: 10,
                project: [],
                orderAsc: true,
                activeSortBy: 'mls_address',
                cidSelected: 0,
                MLSuploadForm: {
                    listPrice: '',
                    listPriceDate: null,
                    buyerIncentives: '',
                    realtorIncentives: '',
                    backupText: '',
                    files: null,
                    priceBackup: '',
                    soldDate: null
                },
                mlsStatus: [{text: '', value: 11482}, {text: 'Active', value: 8197}, {text: 'Sold', value: 8198}],
                priceBackupOption: [
                    {text: '', value: ''},
                    {text: 'Verified', value: 'Verified'},
                    {text: 'Semi-Verified', value: 'Semi-Verified'},
                    {text: 'Educated Estimated', value: 'Educated Estimated'}
                ],

            }
        },
        methods:{
        showUnitPreview(){
            this.$refs['summary-table-modal'].show()
        },
        refreshResults(listingType) {
            switch (listingType) {
                case 'active':
                    if(this.project.mls_last_date_active.filter(row => row.rowSelected === true).length > 0)
                        this.project.mls_last_date_active = this.project.mls_last_date_active.filter(row => row.rowSelected === true)  
                    
                    this.totalRows.active = this.project.mls_last_date_active.length
                    break;
                
                case 'sold':
                if(this.project.mls_last_date_sold.filter(row => row.rowSelected === true).length > 0)
                    this.project.mls_last_date_sold = this.project.mls_last_date_sold.filter(row => row.rowSelected === true)  
                    this.totalRows.sold = this.project.mls_last_date_sold.length
                break;
                    
                default:
                    break;
            }
        },
            fieldsOn: function () {
                this.reportFields = [];  
            },
            formatToWholeNumber(value, key, row){ 
                return (typeof value == 'string' && value != '0') || (typeof value == 'number' && value > 0) ? `$${Math.round(value).toLocaleString()}` : '-'
            },
            setRpdTableHeader(){ 
                const vm = this
                switch(vm.current_user.role) {
                        case 'developer':
                        if(vm.listingTab == 'active') { 
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                                let list_price = Number(row.mls_list_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true },
                                            { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            },sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                                return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                                let list_price = Number(row.net_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        else {
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber, sortable: true },
                                            { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf'},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                            { label: "Sold Date", key:'mls_sold_date'},
                                            { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        break;

                        case 'developerbc':
                        if(vm.listingTab == 'active') { 
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                                let list_price = Number(row.mls_list_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true },
                                            { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            },sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                                return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                                let list_price = Number(row.net_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        else {
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber, sortable: true },
                                            { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf'},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                            { label: "Sold Date", key:'mls_sold_date'},
                                            { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        break;

                        case 'realtor':
                            vm.rpdTableList1 = [
                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                            { label: "Type", key: 'mls_beds', sortable: true},
                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                return Number(value) ? `${Number(value)}` : '-'
                            }, sortable: true },
                            { label: "$PSF", key: 'mls_psf', sortable: true },
                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                        ]
                        vm.rpdTableList2 = [
                        { label: "Realtor Incentives", key:'realtor_incentives'},
                        { label: "Status", key:'mls_status'},
                        { label: "Sold Date", key:'mls_sold_date'},
                        { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                            return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                        }, sortable: true},
                        { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                        { label: "Net Price", key:'net_price'},
                        { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                        { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                        { label: "Baths", key: 'mls_baths', sortable: true  },
                        { label: "Parking", key: 'stalls' },
                        { label: "Stalls", key: 'mls_parking' },
                        { label: "List Date", key: 'mls_list_date', sortable: true  },
                        { label: "View", key: 'orientation', sortable: true  },
                        { label: "Levels", key: 'levels', sortable: true  }
                        ]
                        break;

                        case 'admin': //admin role
                       
                            vm.rpdTableList1 = [
                                    { label: 'Selected', key: 'checkbox'},
                                    { label: "Unit #", key: 'mls_address', sortable: true },
                                    { label: "PID", key: 'mls_pid', sortable: true },
                                    { label: "Plan Name", key: 'mls_plan_name', sortable: true },
                                    { label: "Beds", key: 'custom_mls_beds', sortable: true, formatter:(value, row, key) => {
                                        return key.mls_beds
                                    } },
                                    { label: "FP Specs", key: 'fp_specs' },
                                    { label: "Baths", key: 'mls_baths', sortable: true  },
                                    { label: "Size", key: 'mls_unit_size' , sortable: true},
                                    { label: "View", key: 'orientation', sortable: true  },
                                    { label: "Balcony", key: 'balcony',sortable: true},
                                    { label: "Balcony Area", key: 'balcony_area',sortable: true},
                                    { label: "Parking", key: 'stalls' },
                                    { label: "Stalls", key: 'mls_parking' },
                                    { label: "List Date", key: 'mls_list_date', sortable: true, tdClass:'_no-wrap'  },
                                    { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                        return Number(value) ? `${Number(value)}` : '-'
                                    }, sortable: true },
                                    { label: "$PSF", key: 'mls_psf', formatter: this.formatToWholeNumber,sortable: true },
                                    { label: "Buyer Incentives", key:'buyer_incentives',sortable: true},
                                    { label: "Net Price", key:'list_price_net',formatter: (value, row, key) => {
                                        let res = Number(key.mls_list_price - key.buyer_incentives)
                                        return res > 0 ? `$${res.toLocaleString()}` : '-'
                                    },sortable: true},
                                    { label: "Net $PSF", key:'custom_net_psf',formatter: (value, row, key) => {
                                        let netPrice = Number(key.mls_list_price - key.buyer_incentives)
                                        let res = Math.round(Number(netPrice / key.mls_unit_size))
                                        return res > 0 ? `$${res.toLocaleString()}` : '-'
                                    },sortable: true},
                                    { label: "Price Date", key: 'mls_price_date',sortable: true, tdClass:'_no-wrap'  }, 
                                    { label: "Sold Date", key:'mls_sold_date',sortable: true},
                                    { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                        return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                    }, sortable: true},
                            ]
                            vm.rpdTableList2 = [
                            { label: "Realtor Incentives", key:'realtor_incentives'},
                            { label: "Status", key:'mls_status'},
                            { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                            { label: "Net Price", key:'net_price'},
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "Price Status", key: 'price_back_up' },
                            ]
                        break;

                        default:
                        if(vm.listingTab == 'active') { 
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  }, 
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', formatter: (value, key, row) => { 
                                                let list_price = Number(row.mls_list_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true },
                                            { label: "Incentives", key:'buyer_incentives', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            },sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value, key, row) => {  

                                                return row.net_price ? '$' + Math.round(row.net_price).toLocaleString() : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf',formatter: (value, key, row) => { 
                                                let list_price = Number(row.net_price);
                                                let unit_size = Number(row.mls_unit_size);

                                                return list_price && unit_size ? '$' + Number(list_price / unit_size).toFixed(2) : '-'
                                            }, sortable: true},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        else {
                            vm.rpdTableList1 = [
                                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                                            { label: "Unit #", key: 'mls_address', thClass:'pl-2 position-relative pr-5 _w-350px text-left',tdClass:'text-left _w-350px pl-3', sortable: true },
                                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                                            { label: "Type", key: 'mls_beds', sortable: true},
                                            { label: "Baths", key: 'mls_baths' },
                                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                                            { label: "List Price", key: 'mls_list_price', formatter: (value, row, key) => { 
                                                return Number(value) ? `${Number(value)}` : '-'
                                            }, sortable: true },
                                            { label: "$PSF", key: 'mls_psf', sortable: true },
                                            { label: "Incentives", key:'buyer_incentives',sortable: true , thClass: '_w-15-rem'},
                                            { label: "Net Price", key:'net_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                                            { label: "Net $PSF", key:'net_psf'},
                                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                                            { label: "Sold Date", key:'mls_sold_date'},
                                            { label: "Sold Price", key:'mls_sold_price', formatter: (value) => {
                                                return Number(value) ? `$${Math.round(value).toLocaleString()}` : '-'
                                            }, sortable: true},
                            ]
                            vm.rpdTableList2 = [
                            { label: "Status", key:'mls_status'}, 
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View", key: 'orientation', sortable: true  },
                            { label: "Levels", key: 'levels', sortable: true  },
                            { label: "FP Specs", key: 'fp_specs' },
                            ]
                        }
                        break;
                    }   
            },
            formatTypeText(value){
            switch (value) {
                case 's':
                    return 'Studio'
                    break;
            
                default:
                    return value
                    break;
            }
        },
        showBackupTextModal(data) {
            this.backupModalText = data
            this.$root.$emit('bv::show::modal', 'backupTextModal')
        },
        selectAllRows({selectAll, tableRef}){
            console.log(selectAll, tableRef)
            switch(tableRef){
                case 'active-table':  
                    Object.keys(this.project.mls_last_date_active) 
                    .forEach(item => {
                    this.project.mls_last_date_active[item].rowSelected = selectAll
                    })
                    break;
                    
                    case 'sold-table':  
                        Object.keys(this.project.mls_last_date_sold) 
                        .forEach(item => {
                        this.project.mls_last_date_sold[item].rowSelected = selectAll
                        })
                        break;
                    
                    case 'other-table':  
                        Object.keys(this.project.mls_last_date_other) 
                        .forEach(item => {
                        this.project.mls_last_date_other[item].rowSelected = selectAll
                        })
                        break;
            }     
        },
            mlsEntryUpload() {
                let formData = new FormData();
                formData.append('status', this.MLSuploadForm.status);
                formData.append('listPrice', this.MLSuploadForm.listPrice);
                formData.append('listPriceDate', this.MLSuploadForm.listPriceDate);
                formData.append('files', this.MLSuploadForm.floorPlan);
                formData.append('backupText', this.MLSuploadForm.backupText);
                formData.append('buyerIncentives', this.MLSuploadForm.buyerIncentives);
                formData.append('realtorIncentives', this.MLSuploadForm.realtorIncentives);
                formData.append('priceBackup', this.MLSuploadForm.priceBackup);
                formData.append('cid', this.cidSelected);
                formData.append('soldDate', this.MLSuploadForm.soldDate);
                formData.append('uid', this.$route.query.uid);
                var vm = this;
                $.ajax({
					type: 'POST',
					url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_add_entry',
                    data: formData,
                    processData: false,
                    contentType: false,
				}).done(function(response) {
                    location.reload();
                });
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                }   
                return color
            },
            deleteMLSEntry(cid, entry, k1, k2) { 
                if (this.current_user.role.toLocaleLowerCase() =='admin') {
                    if(confirm("Do you really want to delete this MLS Entry?")){
                        var vm = this;
                        $.ajax({
                            type: 'POST',
                            url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_delete',
                            data: {cid: cid.projectId, entry: cid.projectEntry},
                        }).done(function(response) {
                            if (response.success) {
                                vm.project.mls_data[k1].splice(k2, 1);
                            }
                        });
                    }
                }
            },
            editMLSEntry(cid) {
                if (true) {
                    let link = 'mls_entry_edit?cid='+cid.projectId + '&entry=' + cid.projectEntry +'&project='+this.$route.query.project+'&fid='+this.$route.query.fid+'&uid='+this.$route.query.uid+'&sid='+this.$route.query.sid;
                    window.open(link, '_blank');
                }
            },
            activeSort(column) {
                if (this.activeSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.activeSortBy = column;
            },
            soldSort(column) {
                if (this.soldSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.soldSortBy = column;
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                } 
                return color
            },
        },
        mounted: function() {
            const vm = this;
            this.setRpdTableHeader(); 

            $.get({
                url: BACKEND_URL + 'api/get_unit_mix_group?uid=' + this.$route.query.uid + '&unit_key=' + this.$route.query.unit_key + '&size_range=' + this.$route.query.size_range,
            }, (data, status) => {
                if (status === 'success') {
                    if(data){
                        let mapped = new Object(data) 
                        for(const key in mapped){
                            if(mapped.hasOwnProperty(key) && ['mls_last_date_active','mls_last_date_sold'].includes(key)) {
                                if(data[key] != null){ 
                                    data[key].forEach(mlsRow => {
                                         mlsRow.rowSelected = false
                                    }) 
                                }
                            }
                        }
                    vm.project = mapped
                    }
                }
            });
        },
        watch: { 
            totalRows(newVal){
                this.totalRows = newVal
            },
            project(newVal){ 
                const vm = this
                if(newVal.mls_last_date_active && newVal.mls_last_date_active.length != null) {
                    vm.totalRows.active = newVal.mls_last_date_active.length
                }
                if(newVal.mls_last_date_sold && newVal.mls_last_date_sold.length != null) {
                    vm.totalRows.sold = newVal.mls_last_date_sold.length
                }
            }
        },
        computed: {
            ...mapState(useUserStore,['current_user']),
            
            totalListingSummary() {
                const vm= this
                let res = new Array() 

                if(vm.mlsActiveSort) 
                res.splice(0,0,{ 'Total Units' : [...vm.mlsActiveSort]})

                if(vm.mlsSoldSort) 
                res.splice(0,0,{ 'Total Units' : [...vm.mlsSoldSort]})

                if(vm.mlsActiveSort) 
                res.splice(1,0,{ 'Active Units' : vm.mlsActiveSort ? vm.mlsActiveSort.length : '-' })
            
                if(vm.mlsSoldSort) 
                res.splice(2,0,{ 'Confirmed Sold Units' : vm.mlsSoldSort ? vm.mlsSoldSort.length : '-' })

                return res
            },
            unitPreviewSummary() {
                const vm = this
                let activeData = vm.mlsActiveSort ? vm.mlsActiveSort : []
                let soldData = vm.mlsSoldSort ? vm.mlsSoldSort : []
                // let unreleasedData = vm.mlsOtherSort
                
                let mergedData = [...activeData, ...soldData]

                let grouped = mergedData.reduce((acc, {mls_plan_name, mls_status, fp_specs, mls_beds, mls_baths}) => {


                    if (!acc[mls_plan_name]) {
                        acc[mls_plan_name] = { mls_plan_name, units: 0, confirmed_sold: 0, type: {}, baths: 0 };
                    }

                    if(mls_status !== '' && mls_status.toLowerCase() == 'sold')
                        acc[mls_plan_name].confirmed_sold += 1;
                    
                        acc[mls_plan_name].units += 1;

                        acc[mls_plan_name].baths +=  Number(mls_baths);

                        acc[mls_plan_name].type = {
                            mls_beds: mls_beds,
                            fp_specs: fp_specs
                        } 
                    return acc
                },{})
                
                const groupedArray = Object.values(grouped);
                console.log(groupedArray)            
                return groupedArray 
            },
            isAdmin: {
                get() {
                    return this.current_user.role == 'admin'
                }
            },
            mlsSoldSort: function() {
                var ob = this.project.mls_last_date_sold;
                var vm = this;
                if (this.project.mls_last_date_sold && this.project.mls_last_date_sold.length < 2) {
                    return ob;
                }
                if (this.soldSortBy) {
                    if (this.soldSortBy == 'mls_plan_name') { 
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else if (this.soldSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            },
            mlsActiveSort: function() {
                var ob = this.project.mls_last_date_active;
                var vm = this;
                if (this.project.mls_last_date_active && this.project.mls_last_date_active ) {
                    return ob;
                }
                if (this.activeSortBy) {
                    if (this.activeSortBy == 'mls_plan_name') {
                        if(vm.project.mls_last_date_active != null) {
                            if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                            }
                            else {
                                ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                    if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                        return 1;
                                    }
                                    if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                        return -1;
                                    }
                                    if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                });
                            }
                        }
                        
                    }
                    else if (this.activeSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            if(vm.project.mls_last_date_active != null) {
                                ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                            }
                            
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            }
        }
    }
</script>
<style scoped>
    .add-entry-btn {
        border: 1px solid;
        padding: 10px;
        position: absolute;
        cursor: pointer;
    }
</style>